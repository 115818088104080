<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox ? viewBox : '0 0 ' + width + ' ' + height"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title v-if="iconName" :id="iconName" lang="en">{{ iconName }}</title>
    <g :fill="iconColor" :opacity="opacity">
      <component :is="component" v-bind="fill ? { fill } : {}" />
    </g>
  </svg>
</template>

<script setup lang="ts">
import type { Component, PropType } from "vue";
import { computed } from "vue";

const props = defineProps({
  iconName: {
    type: String,
    default: null
  },
  width: {
    type: [Number, String],
    default: 16
  },
  height: {
    type: [Number, String],
    default: 16
  },
  iconColor: {
    type: String,
    default: "currentColor"
  },
  icon: {
    // Can be either a string representing a global component or a component instance itself
    type: [String, Object] as PropType<string | Component>,
    required: true
  },
  opacity: {
    type: [Number, String],
    default: 1
  },
  viewBox: {
    type: String,
    default: ""
  },
  fill: {
    type: String,
    default: ""
  }
});

const component = computed(() =>
  typeof props.icon === "string" ? `icon-${props.icon}` : props.icon
);
</script>
<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
